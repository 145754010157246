<template>
  <div>

    <nav aria-label="breadcrumb" class="p-3">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{name: 'projects-index'}">Projects</router-link>
        </li>

        <li class="breadcrumb-item" v-if="project">
          <router-link :to="{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							}" v-text="`${project.Code} - ${project.Name}`"></router-link>
        </li>

        <li class="breadcrumb-item">
          <router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-index',
						params: {
							uuid: $route.params.uuid,
							cuuid: $route.params.cuuid
						}
					}" v-if="configuration">{{ configuration.Name }} {{ `v. ${configuration.Version}` }}
          </router-link>
        </li>

        <li class="breadcrumb-item active" v-if="localization_project">
          <router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-project_id-index',
						params: {
							uuid: $route.params.uuid,
							cuuid: $route.params.cuuid,
							project_id: $route.params.project_id
						}
					}">{{ localization_project.Name }}
          </router-link>
        </li>

        <li class="breadcrumb-item active">
          Pay table
        </li>

      </ol>
    </nav>

    <div v-if="loading" class="spinner-border ml-3" role="status">
      <span class="sr-only">Loading...</span>
    </div>

    <div v-else class="p-5 bg-light">
      <div>Available translations</div>
      <div class="p-3 d-flex justify-content-around">
        <div v-for="language in languages" class="text-center d-flex flex-column flex-grow-1"
             v-if="validLanguages.length === 0 || validLanguages.includes(language.Code)">
					<button v-if="translations.length != 0" :disabled="language.DefaultForTranslation == 1" class="btn btn-success mr-2" @click="generateTranslations(language)">GENERATE TRANSLATIONS ({{language.Code.toUpperCase()}})</button>
					<div v-if="translations.length != 0">&nbsp</div>
          <router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-project_id-pay_table-language',
						params: {
							uuid: $route.params.uuid,
							cuuid: $route.params.cuuid,
							project_id: $route.params.project_id,
							language: language.Code
							}
					}" class="flex-grow-1 btn mr-2"
                       :class="{'btn-primary': translations.includes(language.Code), 'btn-info': !translations.includes(language.Code)}">
            <div>{{ language.Name }}</div>
          </router-link>
          <div class="text-small">{{ !translations.includes(language.Code) ? 'Missing' : 'Done' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: "index",
  data: () => ({
    languages: [],
    loading: false,
    project: null,
    configuration: null,
    localization_project: null,
    translations: []
  }),
  async mounted() {
    this.loading = true
    this.project = await api.Projects.getProject(this.$route.params.uuid)
    this.configuration = await api.Projects.getProjectConfig(this.$route.params.cuuid)
    this.localization_project = await api.Localizations.LocalizationProjects.get(this.$route.params.project_id)
    this.translations = await api.Localizations.LocalizedPaytable.getTranslations(this.$route.params.project_id)
    this.languages = await api.Localizations.Languages.getList()
    this.loading = false
  },
  computed: {
    validLanguages() {
      let limitations = (this.$store.getters['User/getUser'].Limitations)
      let languages = []

      if (limitations !== null) {
        languages = limitations.languages
      }

      return languages;
    },
  },
  methods: {
	async generateTranslations(language) {
      this.loading = true
      var response = await api.Localizations.LocalizationProjects.generatePaytableTranslations({
        LocalizationProjectfk: this.$route.params.project_id,
				language: language
      }).finally(() => this.loading = false);
      alert(response);
      this.$router.go(0);
    }
  }
}
</script>

<style scoped>

</style>
